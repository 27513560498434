import React from "react";
import { StyledSectionTitle } from "../../common-styles";
import TechnologyStackItem from "./TechnologyStackItem";
import { TECHNOLOGY_STACK } from "../../constants";
import { 
    StyledTechnologyStackWrapper,
    StyledTechnologyStackItems
} from './styled';

const TechnologyStack = () => {
    return (
        <StyledTechnologyStackWrapper>
            <StyledSectionTitle>Our tech stack</StyledSectionTitle>
            <StyledTechnologyStackItems>
                {TECHNOLOGY_STACK.map((props) => <TechnologyStackItem {...props} /> )}
            </StyledTechnologyStackItems>
        </StyledTechnologyStackWrapper>
    )

};

export default TechnologyStack;