import React from "react"
import { ReactSVG } from "react-svg"
import { StyledTechnologyStackItem, StyledTechnologyStackItemName } from "../styled";

const TechnologyStackItem = ({ icon, name }) => {
  return (
    <StyledTechnologyStackItem>
      <ReactSVG src={icon} />
      <StyledTechnologyStackItemName>
        {name}
      </StyledTechnologyStackItemName>
    </StyledTechnologyStackItem>
  )
}

export default TechnologyStackItem
