import React from "react"
import { StyledSectionTitle, StyledMarginWrapper } from "../../common-styles"
import TechnologyStackItem from "./TechnologyStackItem"
import { TECHNOLOGY_STACK, TECH_STACK_SECTION_TITLE } from "../../constants"
import {
  StyledTechnologyStackWrapper,
  StyledTechnologyStackItems,
} from "./styled"

const TechnologyStack = () => {
  return (
    <StyledMarginWrapper>
      <StyledTechnologyStackWrapper>
        <StyledSectionTitle>
            {TECH_STACK_SECTION_TITLE}
        </StyledSectionTitle>
        <StyledTechnologyStackItems>
          {TECHNOLOGY_STACK.map(props => (
            <TechnologyStackItem {...props} />
          ))}
        </StyledTechnologyStackItems>
      </StyledTechnologyStackWrapper>
    </StyledMarginWrapper>
  )
}

export default TechnologyStack
