import styled from "styled-components";
import { TABLET_MAX_WIDTH, MOBILE_MIN_WIDTH } from "@constants"

export const StyledTechnologyStackWrapper = styled.div``;

export const StyledTechnologyStackItems = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;

    @media only screen and (max-width: ${TABLET_MAX_WIDTH}px) {
        grid-template-columns: repeat(3, 1fr);
  };

  @media only screen and (max-width: ${MOBILE_MIN_WIDTH}px) {
    grid-template-columns: repeat(2, 1fr);
    svg {
      height: 3.25rem !important;
      width: 3.25rem !important;
    }
  };
`;

export const StyledTechnologyStackItem = styled.div`
    background: #3A1A3C;
    display: flex;
    flex-direction: column;
    padding: 16px 0;
    text-align: center;
    gap: 3px;
    
`;


export const StyledTechnologyStackItemName = styled.span`
  color: #ffffff;
  font-family: "Nunito Sans";
  font-size: 1.1rem;
  font-weight: 200;
  letter-spacing: 0.48px;
`;
